import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-solid-svg-icons'
import styles from './CookieNotice.module.scss'
import { Button, Group, Stack } from '@mantine/core';
import { logEvent } from '../lib/logger';

export default function CookieBanner({hideCookieNotice} : {hideCookieNotice?:boolean})
{
  
    const [showBanner, setShowBanner] = useState(!hideCookieNotice);

    //console.log(hideCookieNotice);

    useEffect(
        () =>
        {
            if (!hideCookieNotice)
            {
            let ck: Cookies | undefined;
            ck = new Cookies();
            let ca : boolean = (ck.get('rus_cookies_accepted'));
           
            setShowBanner(!ca);
            }
        }, []
    );

    function acceptAllCookies()
    {
        let ck = new Cookies();

        ck.set("rus_cookies_accepted",'true', {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800)});        
        ck.set("rus_allow_tracking",'true', {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800)});        
        logEvent('tracking_granted', { page: window.location.href, referrer: document.referrer });  
        setShowBanner(false);
    }

    function acceptEssentialCookies()
    {
        let ck = new Cookies();

        ck.set("rus_cookies_accepted",'true', {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800)});
        ck.set("rus_allow_tracking",'false', {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800)});           
        setShowBanner(false);
    }

    if (!showBanner)
    {
       
        return(
        <div className={styles.smallCookieBanner} onClick={() => setShowBanner(true)}><FontAwesomeIcon icon={faCog}/></div>
        );
    }
    else
    {
        return(
            <div className={styles.cookieBanner}>
                <Stack>
            <div>
        We use essential cookies so the site works, and tracking cookies to understand how people use it. Some of the latter are provided by third parties. See our <a target="_blank" href="/privacy-policy">privacy policy</a> for more info.
        </div>
 <Group>
 <Button compact size='xs' onClick={acceptAllCookies}>Accept all cookies</Button><Button compact size='xs' variant='outline' onClick={acceptEssentialCookies}>Only accept essential cookies</Button>
 </Group>
 </Stack>
</div>

            );
    
    }


}



