
import Link from "next/link"

import { CurrencySelector } from "./CurrencyDropdown"
import styles from './Footer.module.scss'

export default function Footer(props: {extraWide?:boolean}) {

    let style = styles.mainContent + (props.extraWide?(" " + styles.extraWide):"");

    return (
        <>
        <br/>
<div className={styles.mainContainer}>
<div className={styles.divider}></div>
<div className={style}>
    
<div className={styles.innerContent}>

<div className={styles.footerSection}>

    <div className={styles.footerHeader}>Online sessions</div>
    <ul>
        <li><Link href='/upcoming-online-sessions'>Upcoming sessions</Link></li>
        <li><Link href='/online'>About the subscription</Link></li>
        <li><Link href='/raw-umber-subscription/'>Purchase a subscription</Link></li>
        <li><Link href='/purchase-gift-voucher/'>Purchase a gift voucher</Link></li>
        <li><Link href='/redeem-gift-voucher/'>Redeem a gift voucher</Link></li>
        <li><Link href='/new-video-search/'>Search the video library</Link></li>
        <li><Link href='/image-search/'>Search the image library</Link></li>
        <li><Link href='/user-images/'>Share your work</Link></li>
        <li><Link href='/showcase/'>Showcase</Link></li>
        <li><Link href='/3d-models/'>3D Models</Link></li>
        <li><Link href='/my-account/'>My account</Link></li>
    </ul>

</div>

<div className={styles.footerSection}>
    <div className={styles.footerHeader}>Courses in Stroud</div>
        <ul>
            <li><Link href='/new-events/'>Upcoming workshops</Link></li>
            <li><Link href='/tuesday-school-with-lizet-dingemans/'>Tuesday school</Link></li>
            <li><Link href='/drop-in-life-drawing-at-raw-umber-studios/'>Drop-in life drawing</Link></li>
        </ul>
    </div>
<div className={styles.footerSection}>
<div className={styles.footerHeader}>About us</div>
        <ul>
            <li><Link href='/about-our-studio/'>About Raw Umber Studios</Link></li>
            <li><Link href='/blog/'>The Raw Umber Blog</Link></li>
            <li><Link href='/contact-us/'>Contact us</Link></li>
            <li><Link href='/frequently-asked-questions/'>FAQs</Link></li>
            <li><Link href='/privacy-policy/'>Privacy policy</Link></li>

        </ul>
    </div>
    
</div>


            </div>
            <div>
            <CurrencySelector/> 
            </div>
            </div>

   
        </>

    )
}