import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Banner.module.scss'
import { Button, Group, Stack, Text } from '@mantine/core';
import Link from 'next/link';


const cookieName = 'rus_paint_off_banner_closed';
const globalHideBanner = false;

export default function Banner({hideBanner}:{hideBanner?: boolean})
{
  
    const [showBanner, setShowBanner] = useState(!(hideBanner || globalHideBanner));

    //console.log(hideBanner || globalHideBanner);

    //console.log(!(hideBanner || globalHideBanner));
    //console.log(showBanner);

    useEffect(
        () =>
        {
            if (!(hideBanner || globalHideBanner))
            {
            let ck: Cookies | undefined;
            ck = new Cookies();
            let ca : boolean = (ck.get(cookieName));
           
            setShowBanner(!ca);
            }
        }, []
    );

    function closeBanner()
    {
        let ck = new Cookies();

        ck.set(cookieName,'true', {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800)});        
        setShowBanner(false);
    }

    if (!showBanner)
    {
        return <></>
    }
        return(
            <div className={styles.banner}>
             <div className={styles.bannerContent}><div>Win £1,500 of prizes in our Image Reference Competition. <Link href='/image-prize/2024'>Click here!</Link></div></div>
             </div>
        );


}



