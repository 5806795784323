import { useRawUmberContext } from "../lib/rawumberprovider";

import { useEffect } from "react";
import { useState } from 'react';
import { Modal, Button, Group } from '@mantine/core';

import styles from './CookiePopUp.module.scss';
import { useForm } from '@mantine/form';
import { Stack, TextInput } from '@mantine/core';
import Link from "next/link";
import { logSubscribeToNewsletter, setBrowserEmail } from "../lib/logger";
import Cookies from "universal-cookie";
import useAuth from "../lib/useAuth";
import { Price } from "./Localization";

const cookieName = 'pum-rawumber-popup';

type formValues =
{
  email:string;
}


export  function CookiePopUp()
{

    const { hidePopUp} = useRawUmberContext();
    const {loggedIn} = useAuth();

    const [opened, setOpened] = useState(false);

    let [submitting, setSubmitting] = useState(false);
    let [submitted, setSubmitted] = useState(false);

    async function submitHandler(data : formValues)
    {

      setSubmitting(true);
      await fetch('/n/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials:"include"
      });
      logSubscribeToNewsletter();
      await setBrowserEmail(data.email);

      setSubmitting(false);
      setSubmitted(true);
      setOpened(false);

    }

    const form = useForm<formValues>({
        initialValues: {
          email: '',
        }
    });

    useEffect(() =>
    {
      const cookies = new Cookies();
      
        if (cookies.get(cookieName)!='true')
        {
            setTimeout(() =>
        {
          //reset cookie to get round Safari's 7 day limit
            cookies.set(cookieName,"true", {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 800),  sameSite: "lax", path:"/"});
            
            if (!hidePopUp && !loggedIn)
            {
              setOpened(true);
            }
        }, 10000);
    }
    }, []);
    
    return <>
     <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Subscribe!"
      >
         <div className={styles.container}>

<form onSubmit={form.onSubmit(async (values) => await submitHandler(values))}>
<Stack>
<div>Subscribe to our mailing list and we’ll keep you informed about upcoming online sessions, workshops and events. Plus we’ll give you access to <b>SEVEN</b> of our previous sessions, all for <b>FREE</b>, and a voucher worth <Price gbp={5}/> for a future livestream</div>
<Group align={'flex-end'}>
  <TextInput
    type='email'
    required
    label="Email"
    placeholder="hello@example.com"
    value={form.values.email}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => form.setFieldValue('email', event.currentTarget.value)}
    error={form.errors.email && 'Invalid email/username'}
  />
<Button disabled={submitting} type="submit">Subscribe</Button>

</Group>
</Stack>


<div className={styles.privacyPolicy}>
We won&rsquo;t sell or share your data and you can unsubscribe at any time. You can see our privacy policy <Link href='/privacy-policy'>here</Link>
</div>
</form>
</div>
      </Modal>

    
    </>
}


