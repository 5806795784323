import React, { useEffect, useState } from 'react';
import Image, { StaticImageData } from 'next/image';
import imagesUK from '../images/flags/uk.png'
import imagesUS from '../images/flags/usa.png'
import imageEurope from '../images/flags/eu.png'

import { useRawUmberContext } from "../lib/rawumberprovider";

import styles from './CurrencyDropdown.module.scss'
import { LocalizationInformation } from '../lib/definitions';
import Cookies from 'universal-cookie';

/*
const data = [
  {
    image: imagesUK,
    label: 'GBP',
    value: 'GBP'
  },
  {
    image: imagesUS,
    label: 'USD',
    value: 'USD'
  },
  {
    image: imageEurope,
    label: 'EUR',
    value: 'EUR'
  },

];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: StaticImageData;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(

  ({ image, label, description, ...others }: ItemProps, ref) => 

  {
    return (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Image src={image} alt = {label} />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
    }
);


export function CurrencySelector() {
  
  const { localizationInformation } = useRawUmberContext();
  const [value, setValue] = useState<string | null>(localizationInformation.CurrencyCode);

  return (
    <>
    <Select
      label=""
      itemComponent={SelectItem}
      data={data}
      value={value}
      onChange={setValue}      
      maxDropdownHeight={400}
    />
         </>

  );
}
*/

type CurrencySelection =
{
  image: StaticImageData,
  value: string
}

const data : CurrencySelection[] = [
  {
    image: imagesUK,
    value: 'GBP'
  },
  {
    image: imagesUS,
    value: 'USD'
  },
  {
    image: imageEurope,
    value: 'EUR'
  },

];

function renderFlag(flag : CurrencySelection, setOpen:React.Dispatch<boolean>, setSelectedItem: React.Dispatch<string> )
{
  return <div onClick={() => {setOpen(false); setSelectedItem(flag.value)}} className={styles.listItem} key = {flag.value}><Image src={flag.image} alt={flag.value}></Image></div> 
}
function renderFlags(setOpen:React.Dispatch<boolean>, setSelectedItem: React.Dispatch<string>)
{
  return data.map(flag => {return renderFlag(flag, setOpen, setSelectedItem); });
}

function renderSelectedItem(setOpen: React.Dispatch<boolean>, search:string)
{  
  return (data.map(flag =>
    {
        if (flag.value == search)
        {
          return <div key='selected' onClick={() => {setOpen(true)}}><Image src={flag.image} alt={flag.value}></Image></div>
        }
    }));

  }


export function CurrencySelector() {
  const { localizationInformation, setLocalizationInformation } = useRawUmberContext();
  const [ open, setOpen] = useState(false);
  const [ selectedItem, setSelectedItem] = useState(localizationInformation?.CurrencyCode);

  useEffect( ()=>
  {

    if (localizationInformation)
    {

        setSelectedItem(localizationInformation.CurrencyCode);
    }
  }, []);

  useEffect(() =>
  {

    if (localizationInformation)
    {
      let newValue : LocalizationInformation
      =
      {      
        ...localizationInformation,
      };
  
      if (selectedItem)
      {
        newValue.CurrencyCode = selectedItem;
      }  


      setLocalizationInformation(newValue);

       //only set the cookie if it's already been set
    //ie if it's already been read from the server

    let ck = new Cookies();
    if (ck.get("rus_localization_information"))
    { 
      ck.set("rus_localization_information", newValue, {secure:true});

    }
    }


  

   

  }
  , [selectedItem]);

  let si = localizationInformation==null?"GBP":localizationInformation.CurrencyCode;

  return (
    <>
    
  <div className={styles.list}>
  {!open && renderSelectedItem(setOpen, si)}
    {open && renderFlags(setOpen, setSelectedItem)}
    </div>
    </>
  )

}