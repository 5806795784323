import { DateTime, DateTimeFormatOptions } from "luxon";
import { useEffect, useState } from "react";

import { CurrencyRate, LocalizationInformation } from "../lib/definitions";

import { useRawUmberContext } from "../lib/rawumberprovider";
import { consoleLog } from "../lib/logger";

export function localPrice(gbp: number, localizationInformation: LocalizationInformation | null, currencies: CurrencyRate[] | null, multiplier?: number)
{


    const m = multiplier??1;

    function GBPtoEUR(gbp: number) : number
    {
        let y = currencies?.find(x=>(x.GBP == gbp))?.EUR;
        return (typeof y === 'undefined'?gbp:y);
    }

    function GBPtoUSD(gbp: number)
    {
        let y = currencies?.find(x=>(x.GBP == gbp))?.USD;
        return (typeof y === 'undefined'?gbp:y);
    }

    switch (localizationInformation?.CurrencyCode)
    {
        case "EUR":
            return "€" + formatNumber(m * GBPtoEUR(gbp));
        case "USD":
            return "$" + formatNumber(m * GBPtoUSD(gbp));
        default:
            return "£" + formatNumber(m * gbp);

    }

}

export function Price(props: {gbp: number, multiplier?: number})
{
    const { localizationInformation, currencies } = useRawUmberContext();

    return <>{localPrice(props.gbp, localizationInformation, currencies, props.multiplier)}</>
}

export function currencyCode(currency: string)
{
    switch (currency.toUpperCase())
    {
        case "GBP":
            return '£';
        case "EUR":
            return '€';
        case "USD":
            return '$';
    }
    
    return "?";
}

export function formattedDateFromString(isoString : string)
{
    let dt = DateTime.fromISO(isoString);
    let month = dt.setLocale('en-gb').monthLong;
    let day = dt.setLocale('en-gb').weekdayLong;

    return `${day} ${dt.day} ${month}, ${dt.year}`;
}

export function dateString(dt : DateTime)
{
    let month = dt.setLocale('en-gb').monthLong;
    let day = dt.setLocale('en-gb').weekdayLong;

    return `${day} ${dt.day} ${month}, ${dt.year}`;
}

function timeString(dt: DateTime)
{
    let localHours = dt.hour;
    let localMinutes = dt.minute;

    let pm = (localHours>=12);
    let smallHours = pm?(localHours-12):localHours;
    if (smallHours == 0)
    {
        smallHours = 12;
    }

    if (localMinutes==0)
    {
        return smallHours + ' ' + (pm?'PM':'AM');
    }

    let x = smallHours + ':' + localMinutes.toLocaleString('en-GB', {minimumIntegerDigits: 2}) + ' ' + (pm?'PM':'AM');
    return x;

}

export function DSTWarning(dt: DateTime)
{
    let dt1 = dt.setZone("America/Chicago");
    let dt2 = dt.setZone('Europe/London');

    return (dt1.isInDST != dt2.isInDST);
}
export function DateAndTime(props: {dateTimeISO : string, showTime: boolean})
{

    let dtUTC = DateTime.fromISO(props.dateTimeISO).toUTC();
    let dtUK = dtUTC.setZone('Europe/London');

    //const { localizationInformation, timeOffset } = useRawUmberContext();
    //const [localDateTime, setLocalDateTime] = useState(dt);
    const [timeZone, setTimeZone] = useState('UK time');

    //consoleLog(dtUK.toString());
    
    const [dateString, setDateString] = useState(dateAndTimeString(dtUK, props.showTime, timeZone));

    useEffect(()=>
    {

        let dt2 = dtUTC.toLocal();
        let tz =dt2.offsetNameShort;
        setTimeZone(tz);
        setDateString(dateAndTimeString(dt2, props.showTime, tz));

    }, []);

    if (!props.dateTimeISO)
    {
        return<></>
    }

    let format : DateTimeFormatOptions =  {weekday: 'long', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    return <>{dateString}</>
}

function dateAndTimeString(dt: DateTime, showTime: boolean, timeZone: string)
{   
    if (!showTime)
    {
        return dateString(dt);
    }
    else
    {
        return `${dateString(dt)} at ${timeString(dt)} (${timeZone})`;
    }


}

export function LocalTimeFromUK(props: {hours: number, minutes?:number})
{

    const date = DateTime.fromObject({hour: props.hours, minute: (typeof props.minutes === 'undefined'?0:props.minutes)}, {zone: "Europe/London"});
    const [localDate, setLocalDate] = useState(date);
    const [timeZone, setTimeZone] = useState("UK Time");

    useEffect(() =>
    {
        const d = DateTime.fromObject({ hour: props.hours, minute: (typeof props.minutes === 'undefined'?0:props.minutes)}, {zone: "Europe/London"});
        let d2 = date.toLocal();
        setLocalDate(d2);
        setTimeZone(d2.offsetNameShort);
    
    }, []);

    let t = timeString(localDate);
    
    return <>{t} ({timeZone})</>
    


}
function formatNumber(n: number) : string
{
    if ((n%1) == 0)
    {
        return '' + n;
    }

    return '' + n.toFixed(2);

}